<template>
  <div class="container-fluid">
    <VCard>
      <div slot="header" class="w-100">
        <el-page-header
          title="Go Back"
          class="pull-left mt-1"
          :content="`${$t('Case Activities')} (${totalEntityCount})`"
          @back="$router.push({ name: 'InternalAIRCustomerDetails', params: $route.params })"
        />

        <VButton
          v-if="caseId"
          icon="times"
          size="xx-small"
          class="pull-left ml-4 mt-1"
          text="Clear Case Filter"
          theme="outline-primary"
          @click="caseId = ''"
        />
      </div>

      <hr class="mb-2 mt-2 bc-dark-5 w-100" />

      <el-table
        class="w-100"
        :data="tableData"
        height="calc(100vh - 390px)"
        empty-text="No records found"
        v-loading="$wait.is($waiters.Customer.AIRCustomers)"
        :default-sort="{ prop: 'publishedDate', order: 'descending' }"
        @sort-change="onSortChange"
      >
        <el-table-column sortable prop="caseId" label="Case ID" />

        <el-table-column sortable prop="type" label="Type" />

        <!-- Data -->
        <el-table-column width="100px" prop="data" label="Data">
          <template slot-scope="{ row }">
            <el-popover placement="bottom" width="400" trigger="click">
              <JsonViewer copyable :value="row.data" class="m-n3" />
              <el-button size="mini" slot="reference">View</el-button>
            </el-popover>
          </template>
        </el-table-column>

        <!-- Published Date -->
        <el-table-column sortable prop="publishedDate" width="180px">
          <!-- Header -->
          <template slot="header">
            Published Date
            <em class="el-icon-info ml-1" v-tooltip="'Date the event occurred in the AIR instance'" />
          </template>
          <!-- Value -->
          <VDateTime slot-scope="{ row }" :value="row.publishedDate" reverse />
        </el-table-column>

        <!-- Created At -->
        <el-table-column sortable prop="createdAt" width="180px">
          <!-- Header -->
          <template slot="header">
            Created Date
            <em class="el-icon-info ml-1" v-tooltip="'Date the event was sent to the cloud'" />
          </template>
          <!-- Value -->
          <VDateTime slot-scope="scope" :value="scope.row.createdAt" reverse />
        </el-table-column>
      </el-table>

      <div class="w-100 p-3 text-center">
        <!-- Pagination -->
        <el-pagination
          background
          :page-size="50"
          layout="prev, pager, next"
          :page-count="totalPageCount"
          @current-change="fetchActivities"
          :current-page.sync="currentPage"
        />
      </div>
    </VCard>
  </div>
</template>

<script>
import { AIRCustomerService } from '@/api/Services/AIRCustomerService'

export default {
  name: 'InternalAIRCaseActivities',

  data() {
    return {
      tableData: [],
      caseId: this.$route.params.caseId,
      totalPageCount: 1,
      totalEntityCount: 0,
      currentPage: 1,
      sortBy: 'publishedDate',
      sortType: 'DESC',
    }
  },

  created() {
    this.fetchActivities()
  },

  methods: {
    onSortChange({ prop, order }) {
      this.sortType = order === 'ascending' ? 'ASC' : 'DESC'
      this.sortBy = prop
      return this.fetchActivities()
    },

    fetchActivities() {
      return this.$request(async () => {
        const { data } = await AIRCustomerService.GetCaseActivities(this.$route.params.id, {
          pageNumber: this.currentPage,
          sortBy: this.sortBy,
          sortType: this.sortType,
        })

        this.totalPageCount = data.result.totalPageCount
        this.totalEntityCount = data.result.totalEntityCount
        this.tableData = data.result.entities.map((item) => {
          item.publishedDate = new Date(item.publishedDate)
          item.createdAt = new Date(item.createdAt)
          return item
        })
      }, this.$waiters.Customer.AIRCustomers)
    },
  },
}
</script>

<template>
  <div id="create-instance-form">
    <!-- Warning -->
    <VAlert v-if="errorMessage" class="mb-2" type="error" :title="$t('Error')" :message="errorMessage" />

    <!-- Steps -->
    <InternalCreatingSteps v-if="startCreating" :form="form" @on-complete="removeUnloadEvent" />

    <!-- Form -->
    <VCard v-else class="w-100 mx-auto" :title="$t('Create an AIR Instance')">
      <hr class="mb-3 mt-2 bc-dark-5 w-100" />
      <form @submit.prevent="createInstance" autocomplete="off">
        <!-- First & Last Name -->
        <div class="form-row flex-row">
          <VTextBox
            name="First Name"
            class="flex-1 mr-2"
            label="First Name"
            v-validate="'required|alphanumeric'"
            v-model="form.firstname"
          />
          <VTextBox
            name="Last Name"
            class="flex-1 ml-2"
            label="Last Name"
            v-validate="'required|alphanumeric'"
            v-model="form.lastname"
          />
        </div>
        <!-- Email -->
        <div class="form-row">
          <VTextBox label="Email" name="Email" v-model="form.email" v-validate="'required|email'" />
        </div>
        <div class="form-row flex-row">
          <!-- Company -->
          <VTextBox
            class="flex-1 mr-2"
            name="Company"
            label="Company"
            v-validate="'required|alphanumeric|max:50|min:2'"
            v-model="form.company"
          />
          <!-- Domain -->
          <div class="d-flex flex-items-start flex-1 ml-2">
            <VTextBox name="Domain" :label="$t('Domain')" v-model="form.domain" v-validate="'required'" />
            <VTextBox disabled class="domain-input" value=".binalyze.io" style="margin-top: 25px" />
          </div>
        </div>
        <!-- Password -->
        <div class="form-row flex-row">
          <VPasswordStrengthMeter ref="passMeter" class="flex-1 mr-2" :value="form.password">
            <VTextBox
              type="password"
              ref="NewPassword"
              name="NewPassword"
              :label="$t('Password')"
              v-model="form.password"
              :data-vv-as="$t('Password')"
              @focus="$refs.passMeter.show = true"
              @blur="$refs.passMeter.show = false"
              v-validate="'required|strong_password|max:100'"
              :placeholder="$t('Enter a password to log in to AIR')"
            />
          </VPasswordStrengthMeter>
          <!-- Confirm Password -->
          <VTextBox
            type="password"
            class="flex-1 ml-2"
            name="ConfirmPassword"
            v-model="form.confirmPassword"
            :label="$t('Confirm Password')"
            :data-vv-as="$t('Confirm Password')"
            v-validate="'required|strong_password|confirmed:NewPassword'"
          />
        </div>
        <!-- Note -->
        <h3 v-text="$t('Note')" class="c-dark-80" />
        <hr class="mt-1 mb-2 bc-dark-5 w-100" />
        <div class="form-row flex-row">
          <VTextArea
            name="Note"
            class="flex-1"
            nolabel
            rows="3"
            placeholder="You can add extra information and then see it on the one customers page.       (Customer does not see)"
            v-model="form.note"
          />
        </div>
        <!-- License -->
        <h3 v-text="$t('License')" class="c-dark-80" />
        <hr class="mt-1 mb-3 bc-dark-5 w-100" />

        <div class="form-row flex-row">
          <!-- Period (days) -->
          <VTextBox
            type="number"
            min="5"
            max="5000"
            name="Period"
            class="flex-1 mr-2"
            v-validate="'required'"
            :label="$t('Period (days)')"
            v-model="form.licensePeriod"
          />
          <!-- Asset Count -->
          <VTextBox
            type="number"
            min="5"
            max="10000"
            name="Asset Count"
            class="flex-1 ml-2"
            v-validate="'required'"
            :label="$t('Asset Count')"
            v-model="form.licenseClientCount"
          />
        </div>
        <!-- Create -->
        <hr class="my-3 bc-dark-5 w-100" />
        <div>
          <VButton
            :text="$t('Create Instance')"
            :loading="$wait.is($waiters.Customer.Create)"
            @click="createInstance"
            class="ml-auto"
            theme="primary"
          />
        </div>
      </form>
    </VCard>
  </div>
</template>

<script>
import { OneCustomerService } from '@/api/Services/OneCustomerService'
import InternalCreatingSteps from './InternalCreatingSteps'
import { HTTPClient } from '@/api/HTTPClient'
import { sleep } from '@/utils/Helpers'

export default {
  name: 'InternalCreateAIRInstance',

  components: {
    InternalCreatingSteps,
  },

  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        domain: '',
        company: '',
        password: '',
        confirmPassword: '',
        licenseKey: this.$route.query.licenseKey,
        licensePeriod: 18,
        licenseClientCount: 50,
        note: '',
      },
      errorMessage: null,
      startCreating: false,
    }
  },

  created() {
    Object.assign(this.form, this.$route.query)

    this.form.company = unescape(this.form.company)
    this.form.firstname = unescape(this.form.firstname)
    this.form.lastname = unescape(this.form.lastname)

    if (this.form.licenseKey) {
      HTTPClient.setParam('licenseKey', this.form.licenseKey)
      this.startCreating = true
    }

    if (this.form.email && !this.form.domain) {
      this.generateDomainByName()
    }
  },

  beforeDestroy() {
    this.removeUnloadEvent()
  },

  methods: {
    removeUnloadEvent() {
      window.removeEventListener('beforeunload', this.beforeUnload)
    },

    beforeUnload(e) {
      e.preventDefault()
      e.returnValue = false
    },

    async generateDomainByName() {
      const emailDomain = this.form.email.split('@')[1]?.split('.')?.[0]
      const { data } = await OneCustomerService.GenerateDomainByName(emailDomain || this.form.company)
      this.form.domain = data.result.domain
    },

    async createInstance() {
      if (!(await this.$validator.validateAll())) return

      window.addEventListener('beforeunload', this.beforeUnload)

      this.errorMessage = null

      const onSuccess = async () => {
        const { data } = await OneCustomerService.CreateCustomerInternal(this.form)
        this.form.licenseKey = data.result.licenseKey

        // A psychological wait to reduce 1.5 seconds from the next step.
        await sleep(1500)

        this.$router.replace({
          name: 'InternalCreateAIRInstance',
          params: { fromCreation: true },
          query: { licenseKey: this.form.licenseKey },
        })
        HTTPClient.setParam('licenseKey', this.form.licenseKey)
        this.startCreating = true
      }

      const onError = async (error) => {
        this.errorMessage = error?.response?.data?.message?.toString() || this.$t('Something went wrong')
      }

      this.$request(onSuccess, this.$waiters.Customer.Create, onError)
    },
  },
}
</script>

<style lang="scss">
#create-instance-form {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;

  .domain-input {
    width: 142px;

    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  #change-domain-btn {
    color: $color-blue;
    font-weight: 500;
    font-size: 11px;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

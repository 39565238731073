<template>
  <VCard class="w-100 mx-auto" :title="$t('Creating an AIR Instance')">
    <hr class="mb-4 mt-2 bc-dark-5 w-100" />
    <div>
      <!-- Steps -->
      <div
        v-for="step in creatingSteps"
        :key="step.processingText"
        :id="step.processing ? null : step.id"
        class="d-flex flex-items-center mb-4"
      >
        <!-- Loader -->
        <VCheckLoader size="26px" border="4px" color="#25af54" :loading="step.processing" />
        <!-- Description -->
        <span
          v-text="step.processing ? step.processingText : step.completedText"
          class="fs-big fw-medium c-dark-90 ml-3"
        />
      </div>

      <!-- E-Mail -->
      <div v-if="isCreationComplete" class="text-center">
        <hr class="mb-3 mt-2 bc-dark-5 w-100" />
        <!-- Launch Now -->
        <VButton
          id="btn-launch-now"
          :text="$t('Launch Now')"
          @click="launchAIRInstance"
          class="mx-auto"
          theme="success"
        />
      </div>
    </div>
  </VCard>
</template>

<script>
import * as Helpers from '@/utils/Helpers'
import { OneCustomerService } from '@/api/Services/OneCustomerService'

export default {
  name: 'InternalCreatingSteps',

  props: {
    form: Object,
  },

  data() {
    // !! Important
    // We use id field for google tag manager integration.
    // If we change these id values we must notify marketing team (Emre Çetin)
    const creatingSteps = [
      {
        id: 'creating-server',
        processingText: this.$t('Creating a server...'),
        completedText: this.$t('Server has been created.'),
        processing: true,
      },
      {
        id: 'creating-domain',
        processingText: this.$t('Creating a domain name for your company...'),
        completedText: this.$t('Domain name has been created for your company.'),
        processing: true,
      },
      {
        id: 'air-installation',
        processingText: this.$t('Binalyze AIR is installing...'),
        completedText: this.$t('Binalyze AIR has been installed.'),
        processing: true,
      },
      {
        id: 'air-setup',
        processingText: this.$t('SSL Certificate and other security steps are in progress...'),
        completedText: this.$t('SSL Certificate and other security steps has been completed.'),
        processing: true,
      },
    ]

    return {
      creatingSteps: creatingSteps,
    }
  },

  created() {
    if (this.$route.params.fromCreation) {
      this.createServer()
    } else if (this.$route.query.licenseKey) {
      this.checkStatus()
    }
  },

  computed: {
    isCreationComplete() {
      return this.creatingSteps.every((i) => !i.processing)
    },
  },

  methods: {
    launchAIRInstance() {
      window.location.href = `https://${this.hostname}`
    },

    createServer() {
      return this.$request(async () => {
        await OneCustomerService.CreateServerInternal(this.form)
        await this.checkStatus()
      })
    },

    async checkStatus() {
      let isCompleted = false

      const onSuccess = async () => {
        const { data } = await OneCustomerService.CheckInstanceStatus()
        const status = data.result.status

        this.hostname = data.result.hostname

        if (status.airSetup.completed) {
          await Helpers.sleep(8e3)
          isCompleted = true
          this.$emit('on-complete')
        }

        this.creatingSteps[0].processing = !status.server.completed
        this.creatingSteps[1].processing = !status.domain.completed
        this.creatingSteps[2].processing = !status.airBootstrap.completed
        this.creatingSteps[3].processing = !status.airSetup.completed
      }

      const onError = () => {
        isCompleted = true
        this.$router.push('/')
      }

      do {
        await this.$request(onSuccess, null, onError)
        await Helpers.sleep(1e3)
      } while (!isCompleted)
    },
  },
}
</script>

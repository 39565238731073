<template>
  <div class="container-fluid">
    <VCard>
      <div slot="header" class="d-flex flex-justify-between">
        <h3 v-text="`${$t('One Customers')} (${filteredTableData.length})`" />

        <VTextBox
          nolabel
          size="small"
          name="Search..."
          class="w-100 ml-4"
          style="max-width: 300px"
          v-validate="'required'"
          v-model="searchQuery"
        />
      </div>

      <hr class="mb-2 mt-2 bc-dark-5 w-100" />

      <el-table
        class="w-100"
        empty-text="No records found"
        height="calc(100vh - 320px)"
        :data="filteredTableData"
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
        v-loading="$wait.is($waiters.Customer.OneCustomers)"
      >
        <el-table-column sortable prop="id" width="75px" label="ID" />
        <el-table-column sortable prop="licenseKey" label="License Key" />
        <el-table-column sortable prop="company" label="Company" />
        <el-table-column sortable prop="firstname" label="Full Name">
          <template slot-scope="scope"> {{ scope.row.firstname }} {{ scope.row.lastname }} </template>
        </el-table-column>
        <el-table-column sortable prop="email" label="Email" />
        <el-table-column sortable prop="domain" label="Domain">
          <a
            slot-scope="scope"
            class="c-blue"
            target="_blank"
            rel="noopener noreferrer"
            :href="`https://${scope.row.domain}`"
          >
            {{ scope.row.domain.split('.').slice(0, -2).join('.') }}
            <i class="el-icon-link"></i>
          </a>
        </el-table-column>
        <el-table-column sortable prop="note" label="Note" width="120px">
          <template slot-scope="scope">
            <el-popover placement="left" title="Note" width="200" trigger="hover" :content="scope.row.note || 'N/A'">
              <el-button type="text" size="mini" slot="reference">Show Note</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column sortable prop="createdAt" width="150px" label="Created Date">
          <VDateTime slot-scope="scope" :value="scope.row.createdAt" reverse />
        </el-table-column>
        <el-table-column sortable prop="periodDays" label="Period Days" />
        <el-table-column sortable prop="terminationDate" width="180">
          <!-- Header -->
          <template slot="header">
            Termination Date
            <em class="el-icon-info ml-1" v-tooltip="'4 days after license expiration'" />
          </template>
          <VDateTime
            v-if="row.terminationDate"
            slot-scope="{ row }"
            :value="row.terminationDate"
            date-format="YYYY.MM.DD"
            reverse
          />
          <span v-else v-text="'N/A'" />
        </el-table-column>
        <el-table-column label="Actions" fixed="right" width="90px">
          <template slot-scope="{ row }">
            <el-button size="mini" type="text" @click="onClickDelete(row)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
    </VCard>
  </div>
</template>

<script>
import { OneCustomerService } from '@/api/Services/OneCustomerService'

export default {
  name: 'InternalOneCustomers',

  data() {
    return {
      searchQuery: '',
      tableData: [],
    }
  },

  created() {
    this.fetchCustomers()
  },

  computed: {
    filteredTableData() {
      return this.tableData.filter((data) =>
        Object.keys(data).some((key) =>
          data[key]?.toString()?.toLowerCase()?.includes(this.searchQuery?.trim()?.toLowerCase()),
        ),
      )
    },
  },

  methods: {
    onClickDelete(row) {
      const onSuccess = async () => {
        OneCustomerService.RequestDeleteInstance(row.id)

        const email = row.createdBy || 'huseyin@binalyze.com'
        this.$prompt(`We've sent a confirmation code to ${email}`, 'Confirmation Code', {
          confirmButtonText: 'Delete Instance',
          cancelButtonText: 'Cancel',
          inputErrorMessage: 'Invalid Code',
          inputPlaceholder: 'Confirmation code',
        }).then(async ({ value }) => {
          try {
            await OneCustomerService.DeleteInstanceByConfirmCode({
              confirmCode: value,
              customerId: row.id,
            })
            setTimeout(() => this.fetchCustomers(), 2_000)
            this.$message({ type: 'success', message: 'AIR Instance successfully deleted' })
          } catch (err) {
            this.$message({ type: 'error', message: 'Invalid confirmation code' })
          }
        })
      }

      this.$request(onSuccess, this.$waiters.Customer.OneCustomerDelete)
    },

    fetchCustomers() {
      return this.$request(async () => {
        const { data } = await OneCustomerService.GetCustomers()

        this.tableData = data.result.map((item) => {
          item.createdAt = new Date(item.createdAt)
          item.terminationDate = item.terminationDate ? new Date(item.terminationDate) : null
          return item
        })
      }, this.$waiters.Customer.OneCustomers)
    },
  },
}
</script>

<template>
  <div class="container-fluid">
    <VCard>
      <div slot="header" class="d-flex flex-justify-between">
        <h3 v-text="`${$t('FIS Customers')} (${filteredTableData.length})`" />

        <VButton theme="success" size="small" text="Export" class="ml-auto" @click="onClickExport" />

        <VTextBox
          nolabel
          size="small"
          type="search"
          name="Search..."
          class="w-100 ml-2"
          style="max-width: 230px"
          v-validate="'required'"
          v-model="searchQuery"
        />
      </div>

      <hr class="mb-2 mt-2 bc-dark-5 w-100" />

      <el-table
        class="w-100"
        height="calc(100vh - 320px)"
        empty-text="No records found"
        :data="filteredTableData"
        v-loading="$wait.is($waiters.Customer.AIRCustomers)"
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
      >
        <el-table-column sortable prop="id" width="100px" label="ID" />
        <el-table-column sortable prop="email" label="Email" />
        <el-table-column sortable prop="company" label="Company" />
        <el-table-column sortable prop="caseCount" label="Case Count" width="160" />
        <el-table-column sortable prop="createdAt" label="Created Date" width="200">
          <VDateTime slot-scope="{ row }" :value="row.createdAt" reverse />
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="120">
          <RouterLink slot-scope="{ row }" class="c-blue" :to="{ name: 'InternalAIRCustomerDetails', params: row }">
            <em class="el-icon-view mr-2" />
            Details
          </RouterLink>
        </el-table-column>
      </el-table>
    </VCard>
  </div>
</template>

<script>
import { AIRCustomerService } from '@/api/Services/AIRCustomerService'

export default {
  name: 'InternalAIRCustomers',

  data() {
    return {
      searchQuery: '',
      tableData: [],
    }
  },

  created() {
    this.fetchCustomers()
  },

  computed: {
    filteredTableData() {
      return this.tableData.filter((data) =>
        Object.keys(data).some((key) =>
          data[key].toString().toLowerCase().includes(this.searchQuery.trim().toLowerCase()),
        ),
      )
    },
  },

  methods: {
    onClickExport() {
      window.open(`/api/customers/air/internal/export`, '_blank')
    },

    fetchCustomers() {
      this.$request(async () => {
        const { data } = await AIRCustomerService.GetCustomers()
        this.tableData = data.result.map((item) => {
          item.createdAt = new Date(item.createdAt)
          return item
        })
      }, this.$waiters.Customer.AIRCustomers)
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('VCard',[_c('div',{staticClass:"w-100",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"pull-left"},[_c('el-page-header',{staticClass:"mt-1 mr-3 pull-left",attrs:{"title":"Go Back","content":_vm.headerTitle},on:{"back":function($event){return _vm.$router.push({ name: 'InternalAIRCustomers' })}}}),_c('VButton',{staticClass:"pull-right mt-1",attrs:{"size":"xx-small","theme":"outline-primary","text":_vm.$t('View All Case Activities')},on:{"click":function($event){return _vm.$router.push({ name: 'InternalAIRCaseActivities', params: _vm.$route.params })}}})],1),_c('VTextBox',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-100 ml-2 pull-right",staticStyle:{"max-width":"240px"},attrs:{"nolabel":"","size":"small","type":"search","name":"Search Case ID"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('hr',{staticClass:"mb-2 mt-3 bc-dark-5 w-100"}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$wait.is(_vm.$waiters.Customer.AIRCustomerCaseSummary)),expression:"$wait.is($waiters.Customer.AIRCustomerCaseSummary)"}],staticClass:"w-100",attrs:{"data":_vm.filteredTableData,"height":"calc(100vh - 320px)","empty-text":"No records found","default-sort":{ prop: 'createdAt', order: 'ascending' }}},[_c('el-table-column',{attrs:{"sortable":"","prop":"caseId","label":"Case ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(row.caseId)+" "),_c('RouterLink',{attrs:{"to":{ name: 'InternalAIRCaseActivities', params: row }}},[_c('FaIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View All Activities of Case'),expression:"'View All Activities of Case'"}],staticClass:"ml-2 c-blue fs-small",attrs:{"icon":"search"}})],1)],1)}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"organizationId","label":"Organization ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('span',{},[_vm._v(" "+_vm._s(row.organizationId || 'N/A')+" ")])}}])}),_c('el-table-column',{attrs:{"sortable":"","width":"190px","prop":"billingCycleStartDate","label":"Billing Cycle Start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.billingCycleStartDate)?_c('VDateTime',{attrs:{"value":row.billingCycleStartDate,"date-format":"YYYY.MM.DD","reverse":""}}):_c('span',{},[_vm._v(" Not started yet "),_c('em',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(("Case was created on " + (row.createdDate) + " but no asset was added yet")),expression:"`Case was created on ${row.createdDate} but no asset was added yet`"}],staticClass:"el-icon-info ml-1 c-dark-50"})])}}],null,true)}),_c('el-table-column',{attrs:{"sortable":"","width":"180px","prop":"billingCycleEndDate","label":"Billing Cycle End"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.billingCycleEndDate)?_c('VDateTime',{attrs:{"value":row.billingCycleEndDate,"date-format":"YYYY.MM.DD","reverse":""}}):_c('span',{domProps:{"textContent":_vm._s('-')}})}}],null,true)}),_c('el-table-column',{attrs:{"prop":"daysLeftToBilling","label":"Day(s) Left to Billing","width":"180px"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"totalDays","label":"Total Days","width":"120px"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"totalEndpoints","label":"Total Assets","width":"150px"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"exceptionReason","label":"Exception","width":"200px"}}),_c('el-table-column',{attrs:{"label":"Actions","fixed":"right","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.exceptionReason)?_c('el-popconfirm',{attrs:{"title":"Are you sure?"},on:{"confirm":function($event){return _vm.onClickResetException(row)}}},[_c('el-button',{attrs:{"slot":"reference","size":"mini","type":"text"},slot:"reference"},[_vm._v(" Include in billing ")])],1):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"left","disabled":_vm.canExceptionMade(row),"content":"You cannot use it because the billing cycle has not ended"}},[_c('el-button',{attrs:{"size":"mini","type":"text","disabled":!_vm.canExceptionMade(row)},on:{"click":function($event){return _vm.onClickMarkAsException(row)}}},[_vm._v(" Exclude from billing ")])],1)]}}])})],1),_c('el-dialog',{attrs:{"title":"Exclude from Billing","visible":_vm.showExceptionModal,"width":"470px"},on:{"update:visible":function($event){_vm.showExceptionModal=$event}}},[_c('label',{staticClass:"mt-n2 mb-1 d-block fw-medium"},[_vm._v("Reason")]),_c('p',{staticClass:"mb-2 fs-small"},[_vm._v("You can mark the billing cycle for any purpose. This action just adds a info.")]),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Select or type","allow-create":"","filterable":"","clearable":""},model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}},_vm._l((_vm.reasonList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showExceptionModal = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"primary","disabled":!_vm.selectedReason},on:{"click":_vm.onClickSaveException}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { HTTPClient } from '@/api/HTTPClient'

export class AIRCustomerService {
  static GetCustomers() {
    return HTTPClient.get('/customers/air/internal')
  }

  static GetCaseSummary(customerId) {
    return HTTPClient.get(`/customers/air/internal/${customerId}/case-summary`)
  }

  static GetCaseActivities(customerId, query) {
    return HTTPClient.get(`/customers/air/internal/${customerId}/case-activities`, query)
  }

  static CheckInternalAccess() {
    return HTTPClient.head('/customers/air/internal')
  }

  static MarkBillingCycleAsException(customerId, payload) {
    return HTTPClient.post(`/customers/air/internal/${customerId}/mark-billing-cycle-as-exception`, payload)
  }
}

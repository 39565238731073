import { HTTPClient } from '@/api/HTTPClient'

export class OneCustomerService {
  static ConfirmCustomer(payload) {
    return HTTPClient.post('/customers/one/confirm', payload)
  }

  static CreateCustomer(payload) {
    return HTTPClient.post('/customers/one', payload)
  }

  static CreateServer(payload) {
    return HTTPClient.post('/customers/one/instance/server', payload)
  }

  static CheckInstanceStatus() {
    return HTTPClient.get('/customers/one/instance/check-status')
  }

  static GenerateDomainByName(name) {
    return HTTPClient.get('/customers/one/instance/generate-domain', { name })
  }

  /**
   * Internal Endpoints
   */

  static CheckInternalAccess() {
    return HTTPClient.head('/customers/one/internal')
  }

  static CreateServerInternal(payload) {
    return HTTPClient.post('/customers/one/internal/instance/server', payload)
  }

  static CreateCustomerInternal(payload) {
    return HTTPClient.post('/customers/one/internal', payload)
  }

  static GetCustomers() {
    return HTTPClient.get('/customers/one/internal')
  }

  static RequestDeleteInstance(customerId) {
    return HTTPClient.post('/customers/one/internal/request-delete-instance', { customerId })
  }

  static DeleteInstanceByConfirmCode(payload) {
    return HTTPClient.delete('/customers/one/internal', payload)
  }
}

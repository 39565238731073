<template>
  <div class="container-fluid">
    <VCard>
      <div slot="header" class="w-100">
        <div class="pull-left">
          <el-page-header
            title="Go Back"
            class="mt-1 mr-3 pull-left"
            :content="headerTitle"
            @back="$router.push({ name: 'InternalAIRCustomers' })"
          />

          <VButton
            size="xx-small"
            class="pull-right mt-1"
            theme="outline-primary"
            :text="$t('View All Case Activities')"
            @click="$router.push({ name: 'InternalAIRCaseActivities', params: $route.params })"
          />
        </div>

        <VTextBox
          nolabel
          size="small"
          type="search"
          name="Search Case ID"
          class="w-100 ml-2 pull-right"
          style="max-width: 240px"
          v-validate="'required'"
          v-model="searchQuery"
        />
      </div>

      <hr class="mb-2 mt-3 bc-dark-5 w-100" />

      <el-table
        class="w-100"
        :data="filteredTableData"
        height="calc(100vh - 320px)"
        empty-text="No records found"
        v-loading="$wait.is($waiters.Customer.AIRCustomerCaseSummary)"
        :default-sort="{ prop: 'createdAt', order: 'ascending' }"
      >
        <el-table-column sortable prop="caseId" label="Case ID">
          <span slot-scope="{ row }">
            {{ row.caseId }}
            <RouterLink :to="{ name: 'InternalAIRCaseActivities', params: row }">
              <FaIcon icon="search" class="ml-2 c-blue fs-small" v-tooltip="'View All Activities of Case'" />
            </RouterLink>
          </span>
        </el-table-column>

        <el-table-column sortable prop="organizationId" label="Organization ID">
          <span slot-scope="{ row }">
            {{ row.organizationId || 'N/A' }}
          </span>
        </el-table-column>

        <el-table-column sortable width="190px" prop="billingCycleStartDate" label="Billing Cycle Start">
          <VDateTime
            slot-scope="{ row }"
            v-if="row.billingCycleStartDate"
            :value="row.billingCycleStartDate"
            date-format="YYYY.MM.DD"
            reverse
          />
          <span v-else slot-scope="{ row }">
            Not started yet
            <em
              class="el-icon-info ml-1 c-dark-50"
              v-tooltip="`Case was created on ${row.createdDate} but no asset was added yet`"
            />
          </span>
        </el-table-column>

        <el-table-column sortable width="180px" prop="billingCycleEndDate" label="Billing Cycle End">
          <VDateTime
            slot-scope="{ row }"
            v-if="row.billingCycleEndDate"
            :value="row.billingCycleEndDate"
            date-format="YYYY.MM.DD"
            reverse
          />
          <span v-else v-text="'-'" />
        </el-table-column>

        <el-table-column prop="daysLeftToBilling" label="Day(s) Left to Billing" width="180px" />
        <el-table-column sortable prop="totalDays" label="Total Days" width="120px" />
        <el-table-column sortable prop="totalEndpoints" label="Total Assets" width="150px" />
        <el-table-column sortable prop="exceptionReason" label="Exception" width="200px" />

        <el-table-column label="Actions" fixed="right" width="150px">
          <template slot-scope="{ row }">
            <el-popconfirm v-if="row.exceptionReason" title="Are you sure?" @confirm="onClickResetException(row)">
              <el-button size="mini" type="text" slot="reference"> Include in billing </el-button>
            </el-popconfirm>
            <el-tooltip
              v-else
              class="item"
              effect="dark"
              placement="left"
              :disabled="canExceptionMade(row)"
              content="You cannot use it because the billing cycle has not ended"
            >
              <el-button
                size="mini"
                type="text"
                :disabled="!canExceptionMade(row)"
                @click="onClickMarkAsException(row)"
              >
                Exclude from billing
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- Exception Dialog -->
      <el-dialog title="Exclude from Billing" :visible.sync="showExceptionModal" width="470px">
        <!-- Reasons -->
        <label class="mt-n2 mb-1 d-block fw-medium">Reason</label>
        <!-- Description -->
        <p class="mb-2 fs-small">You can mark the billing cycle for any purpose. This action just adds a info.</p>
        <el-select
          v-model="selectedReason"
          placeholder="Select or type"
          class="w-100"
          allow-create
          filterable
          clearable
        >
          <el-option v-for="item in reasonList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <!-- Footer -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showExceptionModal = false">Cancel</el-button>
          <el-button type="primary" :disabled="!selectedReason" @click="onClickSaveException">Save</el-button>
        </span>
      </el-dialog>
    </VCard>
  </div>
</template>

<script>
import { AIRCustomerService } from '@/api/Services/AIRCustomerService'

export default {
  name: 'InternalAIRCustomers',

  data() {
    return {
      searchQuery: this.$route.query.caseid || '',
      tableData: [],
      selectedRow: null,
      showExceptionModal: false,
      selectedReason: null,
      reasonList: [
        { value: 'PoC', label: 'PoC' },
        { value: 'Exception', label: 'Exception' },
      ],
    }
  },

  created() {
    this.getCustomerSummary()
  },

  computed: {
    headerTitle() {
      const email = this.$route.params.email || ''
      return `${email} - Customer Details (${this.filteredTableData.length})`
    },

    filteredTableData() {
      return this.tableData.filter((data) => data.caseId.toLowerCase().includes(this.searchQuery.trim().toLowerCase()))
    },
  },

  methods: {
    onClickResetException(row) {
      return this.$request(async () => {
        const payload = {
          caseId: row.caseId,
          reason: null,
          billingCycleEndDate: row.billingCycleEndDate,
          billingCycleStartDate: row.billingCycleStartDate,
        }
        await AIRCustomerService.MarkBillingCycleAsException(this.$route.params.id, payload)
        this.getCustomerSummary()
      }, this.$waiters.Customer.MarkAsException)
    },

    canExceptionMade(row) {
      return Boolean(row.billingCycleStartDate && row.billingCycleEndDate)
    },

    onClickMarkAsException(row) {
      this.selectedRow = row
      this.showExceptionModal = true
    },

    async onClickSaveException() {
      await this.$request(async () => {
        const payload = {
          caseId: this.selectedRow.caseId,
          reason: this.selectedReason,
          billingCycleEndDate: this.selectedRow.billingCycleEndDate,
          billingCycleStartDate: this.selectedRow.billingCycleStartDate,
        }

        await AIRCustomerService.MarkBillingCycleAsException(this.$route.params.id, payload)

        this.getCustomerSummary()
        this.showExceptionModal = false
      }, this.$waiters.Customer.MarkAsException)
    },

    getDaysLeftToBilling(row) {
      if (row.billingCycleEndDate) {
        const diff = this.$dayjs(row.billingCycleEndDate).diff(this.$dayjs(), 'day')
        return diff > 0 ? diff : 'Billing Email Sent'
      }
      return '-'
    },

    getCustomerSummary() {
      return this.$request(async () => {
        const { data } = await AIRCustomerService.GetCaseSummary(this.$route.params.id)
        this.tableData = data.result.map((item) => {
          item.daysLeftToBilling = this.getDaysLeftToBilling(item)
          item.billingCycleEndDate = item.billingCycleEndDate ? new Date(item.billingCycleEndDate) : null
          item.billingCycleStartDate = item.billingCycleStartDate ? new Date(item.billingCycleStartDate) : null
          return item
        })
      }, this.$waiters.Customer.AIRCustomerCaseSummary)
    },
  },
}
</script>

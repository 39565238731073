<template>
  <div class="w-100 h-100">
    <div class="w-100 d-flex flex-justify-center my-2 c-blue fs-big fw-medium">
      <RouterLink :to="{ name: 'InternalCreateAIRInstance' }" v-text="'Create an AIR Instance'" />
      <span v-text="'|'" class="c-dark-20 mx-3 fs-big" />
      <RouterLink :to="{ name: 'InternalAIRCustomers' }" v-text="'FIS Customers'" />
      <span v-text="'|'" class="c-dark-20 mx-3 fs-big" />
      <RouterLink :to="{ name: 'InternalOneCustomers' }" v-text="'One Customers'" />
    </div>

    <hr class="bc-dark-5 mb-4 mt-2 w-100" />

    <RouterView />
  </div>
</template>

<script>
import { OneCustomerService } from '@/api/Services/OneCustomerService'
import { AIRCustomerService } from '@/api/Services/AIRCustomerService'

export default {
  name: 'Internal',

  async created() {
    await this.checkAccess()
  },

  methods: {
    async checkAccess() {
      try {
        await OneCustomerService.CheckInternalAccess()
        await AIRCustomerService.CheckInternalAccess()
      } catch (error) {
        this.$router.push({ name: 'SignIn' })
      }
    },
  },
}
</script>
